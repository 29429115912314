import React, { useCallback } from "react";
import { css } from "@emotion/react";

import Layout from "../layouts/default";
import SEO from "../components/seo";

import PageHeader from "../components/page-header";
import { LatestEvents } from "../components/latest-events";
import { Section, Container } from "../styles/layouts";
import Typography from '../styles/typography';

const CalendarPage = () => {

  const GoogleCalendar = useCallback(() => {
    return (
      <iframe
        title="Google Calendar"
        src="https://calendar.google.com/calendar/embed?showTitle=0&amp;showPrint=0&amp;showCalendars=0&amp;height=640&amp;wkst=2&amp;hl=ja&amp;bgcolor=%23F5F8F9&amp;src=a6frt1oirqkm9p3ghk3o664jcg%40group.calendar.google.com&amp;color=%235F6B02&amp;src=i4ucj40ts0j1tiv1vu78dqic7o%40group.calendar.google.com&amp;color=%23B1365F&amp;src=u9itdj60im04vgmvck3h6vv7vc%40group.calendar.google.com&amp;color=%23711616&amp;src=ovpo0kd08uqtfca6eks7kobudg%40group.calendar.google.com&amp;color=%23875509&amp;src=ja.japanese%23holiday%40group.v.calendar.google.com&amp;color=%23125A12&amp;src=o4f5rn81ka4bd29fjbc2ii713dhandqr%40import.calendar.google.com&amp;color=%23A32929&amp;ctz=Asia%2FTokyo"
        css={css`
          border-width:0;
          margin: 0;
          background: transparent;
        `}
        loading="lazy"
        width="100%"
        height="640"
        frameBorder="0"
        scrolling="no"
      ></iframe>
    );
  });

  return (
    <Layout>
      <SEO title="Calendar" description="FC Assort のカレンダーです" />
      <PageHeader title="Calendar" />
      <main>
        <Section>
          <Container>
            <h2 css={Typography.h3}>Current Events</h2>
            <LatestEvents isnow={true} />

            <h2 css={Typography.h3}>Calendar</h2>
            { GoogleCalendar() }
          </Container>
        </Section>
      </main>
    </Layout>
  );
}

export default CalendarPage;
